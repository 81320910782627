import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Import Routes
import './App.css';
import Login from './component/pages/Login';
import Question from './Question'; 
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import Register from './component/pages/Register';
import Tracking from './component/pages/Admin/Tracking';
import RouteChangeTracker from './component/GoogleAnalytics/RouteChangeTracker';

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_ID,
  },
};

const pca = new PublicClientApplication(configuration);

function App() {
  return (
    <MsalProvider instance={pca}>
      
      <BrowserRouter>
      <RouteChangeTracker />
        <Routes> 
       
          <Route exact path="/" element={<Question />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/admin_tracking" element={<Tracking />} />
        </Routes>
      </BrowserRouter>
    </MsalProvider>
  );
}

export default App;
