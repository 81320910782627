
function LoginPageLeftSide(props) {


  return (
    <div
      className="centered w-full  h-screen bg-white md:h-screen p-5 md:p-32 items-center mt-0 md:mt-0 sm:pt-20"
      style={{ backgroundColor:"black",  }}
    >
     <p>Welcome to Mockbot</p>
     
    </div>
  );
}

export default LoginPageLeftSide;
